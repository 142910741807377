import React from "react";
import DisqusComponent from "../../components/blog/disqus";
import TwemojiComponent from "../core/twemoji";
import TagLinkComponent from "./tag-link";
import CategoryLinkComponent from "./category-link";
import LazyloadImage from "../global/lazyloadimage";
import { graphql, useStaticQuery } from "gatsby";
import RelatedPostsComponent from "./related-posts";
const moment = require(`moment`);

const BlogPostDetailComponent = ({ data, relatedPosts }) => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                siteUrl
              }
            }
          }
        `
      )

    let ldJason = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": `${site.siteMetadata.siteUrl}${data.markdownRemark.fields.slug}`
        },
        headline: data.markdownRemark.frontmatter.title,
        description: data.markdownRemark.frontmatter.summary,
        image: data.markdownRemark.frontmatter.teaserImage ? data.markdownRemark.frontmatter.teaserImage.replace(" ", "%20") : data.markdownRemark.frontmatter.socialImage.replace(" ", "%20"),
        author: {
            "@type": "Person",
            name: `Surinder Bhomra`
        },
        datePublished: moment(data.markdownRemark.frontmatter.date).format("YYYY-MM-DD"),
        dateModified: moment(data.markdownRemark.frontmatter.date).format("YYYY-MM-DD")
    }

    return (
        <>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJason) }} />
            <article>
                <div>
                <header className="py-8">
                    <div className="space-y-4 md:space-y-2 text-center">
                        <div>
                            <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-5xl md:leading-14">
                                {data.markdownRemark.frontmatter.title}
                            </h1>
                        </div>
                        <dl className="space-y-10">
                            <div>
                                <dt className="sr-only">Published on</dt>
                                <dd className="flex justify-center items-center text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
                                    <time dateTime={moment(data.markdownRemark.frontmatter.date).format("YYYY-MM-DDThh:mm:ssTZD")} className="flex items-center">
                                        <TwemojiComponent emoji="calendar" size="" />
                                        <span className="ml-1.5">
                                            {moment(data.markdownRemark.frontmatter.date).format("MMM DD, YYYY")}
                                        </span>
                                    </time>
                                    <span className="mx-2">-</span>
                                    <div className="flex items-center">
                                        <TwemojiComponent emoji="hourglass-not-done" size="" />
                                        <span className="ml-1.5">{data.markdownRemark.timeToRead} min read</span>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                        </div>
                    </header>
                    <div
                        className="pb-8 divide-y divide-gray-200 xl:divide-y-0 dark:divide-gray-700 xl:grid xl:grid-cols-4 xl:gap-x-6"
                        style={{ gridTemplateRows: 'auto 1fr' }}>
                        <div className="!border-t-0 divide-y divide-gray-200 dark:divide-gray-700 xl:pb-0 xl:col-span-3 xl:row-span-2">
                            {data.markdownRemark.frontmatter.teaserImage !== "" ? 
                                (<div className="featured-image">
                                    <LazyloadImage 
                                        src={data.markdownRemark.frontmatter.teaserImage.replace(" ", "%20")}  
                                        srcsetSizes={[{ imageWidth: 1000, viewPortWidth: 992 }, { imageWidth: 768, viewPortWidth: 768 }, { imageWidth: 500, viewPortWidth: 500 }]}
                                        alt={data.markdownRemark.frontmatter.title} />
                                </div>) : null}
                            <div className="pt-6 pb-8 prose prose-lg dark:prose-dark max-w-none" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
                            <footer className="xl:hidden pt-6 pb-6">
                                {data.markdownRemark.frontmatter.tags && (
                                    <div className="flex flex-wrap">
                                        <span className="mr-3 font-medium">Tags:</span> 
                                        {data.markdownRemark.frontmatter.tags.map((tag) => (
                                            <TagLinkComponent key={tag} text={tag} />
                                        ))}
                                    </div>)}
                                {data.markdownRemark.frontmatter.categories && (
                                    <div className="flex flex-wrap">
                                        <span className="mr-3 font-medium">Categories:</span> 
                                        {data.markdownRemark.frontmatter.categories.map((category, i) => (
                                            <span key={category.slug}>
                                                <CategoryLinkComponent category={category} /><span className="mr-3">{i !== data.markdownRemark.frontmatter.categories.length - 1 ? " / " : "" }</span>
                                            </span>
                                        ))}
                                    </div>)}
                            </footer>
                            <RelatedPostsComponent posts={relatedPosts} />
                            <DisqusComponent postId={data.markdownRemark.frontmatter.disqusId} postTitle={data.markdownRemark.frontmatter.title} postUrl={data.markdownRemark.fields.slug} />
                        </div>
                        <aside className="hidden xl:block sticky top-14">
                            <div className="py-4 xl:py-8 border-b border-gray-200 dark:border-gray-700">
                                <a
                                    href="/Blog"
                                    className="flex text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
                                    <svg viewBox="0 -9 3 24" className="overflow-visible mr-3 w-auto h-6">
                                        <path
                                        d="M3 0L0 3L3 6"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    Back to the blog
                                </a>
                            </div>
                            <div className="text-sm leading-5 xl:col-start-1 xl:row-start-2">
                                {data.markdownRemark.frontmatter.categories && (
                                    <div className="py-4">
                                        <h2 className="text-sm tracking-wide text-gray-500 dark:text-gray-400 mb-1 font-medium">
                                            Categories
                                        </h2>
                                        <div className="flex flex-wrap">
                                            {data.markdownRemark.frontmatter.categories.map((category) => (
                                                <CategoryLinkComponent key={category.slug} category={category} />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {data.markdownRemark.frontmatter.tags && (
                                    <div className="py-4">
                                        <h2 className="text-sm tracking-wide text-gray-500 dark:text-gray-400 mb-1 font-medium">
                                            Tags
                                        </h2>
                                        <div className="flex flex-wrap">
                                            {data.markdownRemark.frontmatter.tags.map((tag) => (
                                                <TagLinkComponent key={tag} text={tag} />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className="py-4">
                                    <h2 className="text-sm tracking-wide text-gray-500 dark:text-gray-400 mb-1 font-medium">
                                        Support
                                    </h2>
                                    <div className="flex flex-wrap">
                                        <p className="text-base">If you've found anything on this blog useful, you can buy me a coffee. It's certainly not necessary but much appreciated!</p>
                                        <p className="pt-6 text-center">
                                            <a href="https://www.buymeacoffee.com/surinderbhomra" target="_blank" rel="noreferrer">
                                                <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" width="210" height="60" className="my-0 inline" />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </article>
        </>
    )
}

export default BlogPostDetailComponent