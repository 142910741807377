import { Link } from "gatsby";
import React from "react";

const CategoryLinkComponent = ({ category }) => {
  return (
    <Link to={`/Blog/Category/${category.slug}`} rel="tag category">
      <span className="p-category mr-3 text-base dark:text-primary-400 text-primary-500 hover:text-primary-600 dark:hover:text-primary-300">
        {category.name}
      </span>
    </Link>
  )
}

export default CategoryLinkComponent